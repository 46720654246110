.navbar-edit {
    background-color: #E99DCB;
    position: fixed;
    text-align: center;
}

.nav-item.dropdown .dropdown-menu {
   color: white;
    background-color: transparent;
}

.nav-item.dropdown:hover .dropdown-menu {
    display: block;
    background-color: #FF7E5A;
    text-decoration: none;
    color: white !important;
}

#collasible-nav-dropdown{
    color: white;
    background-color: transparent;
}



.titregris{
    color:rgb(145, 143, 143);
    font-weight:bold;
    text-align: left;
    margin-left: 5%;
}

.textcgu{
    color:rgb(145, 143, 143);
    text-align: left;
    margin-left: 5%;
}

.listearticle{
    margin-left: 2%;
}

.color-me{
    color:blue;
}

.animatennl{
    background: linear-gradient(90deg, white, #f65ff3, #7400ff);
	background-size: 300% 300%;
	-webkit-animation: gradient 5s ease infinite;
	        animation: gradient 5s ease infinite;
}

@-webkit-keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.animatennltext{
    animation: Color 4s linear infinite;
   -webkit-animation: Color 4s ease-in-out infinite;
 }
 
 @keyframes Color{
   0%{
     color:black;
   }
   
   33%{
     color:#E99DCB;
   }
   
   66%{
     color:#8099E9;
   }
   
   100%{
     color:black;
   }  
 }
 
 @-moz-keyframes Color{
  0%{
     color:black;
   }
   
   33%{
     color:#E99DCB;
   }
   
   66%{
     color:#8099E9;
   }
   
   100%{
     color:black;
   }  
 }
 
 @-webkit-keyframes Color{
   0%{
     color:black;
   }
   
   33%{
     color:#E99DCB;
   }
   
   66%{
     color:#8099E9;
   }
   100%{
     color:black;
   }  
 }

 .togglecolor{
    color:white !important;
    background-color: #E99DCB !important;
  }

 .Modalbodynnl{
    border: 0;
    border-radius: 3;
    box-shadow: '0 3px 5px 2px rgba(255, 105, 135, .3)';
    border-radius:'25px'
 }

.accueil {
    height: 100vh; 
    align-items: center;
    justify-content: center;
    display: flex;
}

.carousel-control-next-icon{
    background-color: darkgray;
    width: 20px;
    height: 20px;
    border-radius: 20px;
}
.carousel-control-prev-icon{
    background-color: darkgray;
    width: 20px;
    height: 20px;
    border-radius: 20px;
}

  .carousel-indicators li {
    border-top: unset;
    width: 10px;
    border-bottom: unset;
    border: 1px solid transparent;
    border-left-width: 0;
    border-right-width: 0;
    border-radius: 10px;
    background-color: rgba(80, 201, 11, 0.4);
    height: 10px;
    width: 20px;}                                                  
                                                                   
    .carousel-indicators .active {
    border-top: unset;
    border-bottom: unset;
    border: 1px solid transparent;
    border-left-width: 0;
    border-right-width: 0;
    border-radius: 10px;
    background-color: #A91D2A;
    height: 10px;
    width: 50px}
  

.nav-link--active {
    color: white !important;
    transform: scale(1.1);
    font-weight: 10px;
}

.nav-link:hover {
    color: #7400ff !important;
    transform: scale(1.1);
    text-decoration: none !important;
    font-weight: 18px;
    font-size: 1em;
}

.navDropdownitems:hover {
    color: #7400ff !important;
    transform: scale(1.1);
    text-decoration: none !important;
    font-weight: 10px;
    font-size: 1.1em;
}

.dropdown-menu a{
    color: white !important;
   }

.dropdown-menu a:hover{
    background: none;
    color: #7400ff !important;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}
   
.new-line {
  white-space: pre-line;
}

.div404{
    color: #7400ff;
    text-shadow: 0 0 5px #FFF, 0 0 10px #FFF, 2px 2px 2px rgba(206,89,55,0);
    height: 65vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.rotate45 {
    -webkit-transform: rotate(-45deg); 
    -moz-transform: rotate(-45deg);    
    transform:rotate(-45deg);
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}

.verticalcenter {
    vertical-align: middle!important;
    display : inline-block;
}

.menu {
    background-color:rgb(52, 152, 219);
    width: 100%;
}

.table tbody tr:hover td, .table tbody tr:hover th {
    background-color:rgba(189, 195, 199, 0.7);
    color: white !important;
}

a {
  text-decoration: none;
}

a:hover {
    color: #7400ff !important;
  }

.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn.focus:active,
.btn:active:focus,
.btn:focus {
  outline: 0 !important;
  outline-offset: 0  !important;
  -webkit-box-shadow: none !important;
  box-shadow: none  !important;
}

.btn-circle.btn-xl {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.btn-circle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    text-align: center;
}

.btn-circle.btn-xl:hover {
    width: 55px;
    height: 55px;
    border-radius: 50%;
}

.btn-circle:hover {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    text-align: center;
}

.btnvoirplus{
    box-sizing: border-box;
    width: 228px;
    height: 42px;
    top: 412px;
    border: 1px solid #C2C2C2;
    border-radius: 4px;
    color: white;
    background: #FF7E5A;
}

.btnvoirplus:hover{
    box-sizing: border-box;
    width: 228px;
    height: 42px;
    background: #FF5ABD;
    border-radius: 4px;
    text-align: center;
    text-transform: uppercase;
    color: black;
}

input, textarea {
    background: #F5F5F5;
    border-radius: 7.46431px;
    border: 0px;
    color: rgba(0, 0, 0, 0.35);
  }

  input,textarea:focus {
    color: black;
    outline: none;
  }

.contactinput{
    color: rgba(0, 0, 0, 0.35);
}

.btncontactinfo{
    font-size:18px;
    box-sizing: border-box;
    min-height: 42px;
    top: 412px;
    border: 1px solid #C2C2C2;
    border-radius: 4px;
    color:"#000000BF";
    text-align: left;
    padding-left: 20px;
}

.btncontactinfo:hover{
    box-sizing: border-box;
    min-height: 42px;
    background: #FF7E5A;
    border-radius: 4px;
    text-align: left;
    color: #FFFFFF;
}

.footer{
    background-color: #7B4EFB;
    width: 100%;
    height: auto;
}

.contact {
    height: 100%; 
}

.textombrenoir {
    color: rgb(0, 0, 0)
}

.titlebox{
    color:#3498db;
    font-size: 2em;
    font-weight: bold;
}

.soustitlebox{
    color:rgb(145, 143, 143);
    font-size: 1.2em;
}

.btncontact{
    color:white;
    width: 322px;
    height: 62px;
    background: #FF7E5A;
    border-radius: 8px;
    border:0px;
}

.rondorange{
    position: "absolute";
    align-items: center;
    justify-content: center;
    display: flex;
    text-align: center;
    color: #FFFFFF;
    width: 49px;
    height: 49px;
    border-radius: 49px;
    background: #FF7E5A;
}

.nosvaleurs{
    background-color: #6573eb;
    width:100%;
    height: 100%; 
    align-items: center;
    justify-content: center;
    display: flex;
}

.listevaleurs{
    background-color: #8099E9;
    width:100%;
    height: 100%; 
    align-items: center;
    justify-content: center;
    padding: 50px;
}

.cartevaleur{
    height:250px;
    margin-top:50px;
    margin-bottom:25px;
    align-items: center;
    justify-content: center;
    padding: 25px;
    border-radius: '25px';
}

.partenaires{
    height: auto;
    align-content: space-between;
    align-items: center;
    justify-content: center;
    padding: 25px;
    background-color:#FF7E5ACC;
}

.imagepartenaires{
    width:250px;
    height:250px;
    border-radius:250px;
    box-shadow: 8px 8px 8px 8px #aaa;
}

.imagepartenaires:hover{
    width:300px;
    height:300px;
    border-radius:300px;
    box-shadow: 8px 8px 8px 8px #aaa;
    cursor: pointer;
}

.gradientavantages{
    width: 867px;
    height: 516px;
    right: 0px;
    top: 0px;
    background: linear-gradient(140deg, #FF97D3 17%, #1FA5DD 98.71%);
    flex: none;
    order: 1;
    flex-grow: 0;
    z-index: 3;
}

.missions{
    align-items: center;
    justify-content: center;
    padding: 25px;
    background:rgba(31, 165, 221, 0.16);
}

.cardmission{
    height:250px;
    margin-top:50px;
    margin-bottom:25px;
    align-items: center;
    justify-content: center;
    padding: 25px;
}

.avantages{
    align-items: center;
    justify-content: center;
    padding: 25px;
}

.titleavantagebleu{
    color:#1229fb;
    font-size: 2em;
    font-weight: bold;
    text-align: left;
}
.soustitleavantage{
    margin-top: 25px;
    color:#918f8f;
    font-size: 1.2em;
    text-align: left;
}

.titleavantageorange{
    color:#FF7E5A;
    font-size: 2em;
    font-weight: bold;
    text-align: left;
}

.fonctionement{
    margin: 5%;
    width:90%;
    min-height: 510px;
    background: #FFB19629;
    border-radius: 48px;
    align-items: center;
    justify-content: center;
}

.client{
    background-color:white;
    align-items: center;
    justify-content: center;
    padding: 25px;
}

.clientdescription{
    color:#918f8f;
    font-size: 1.2em;
}

.tarifsparentsdiv{
    margin-top: 50px;
    background: #E2F6FF;
}

.tarifsnounoudiv{
    background: #FEE2FF;
}

.titlegardeparent{
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.23em;
    text-transform: uppercase;
    color: #8000FF;
    margin-top:"-200px";
    text-align:"center";
}

.titlegardeentreprise{
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.23em;
    text-transform: uppercase;
    color: #FF7E5A;
    margin-top:"-200px";
    text-align:"center";
}

.titlegardenannies{
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.23em;
    text-transform: uppercase;
    color: #FF7E5A;
    padding-top:"-200px";
    text-align:"center";

}

.titleparents{
    color:#918f8f;
    font-size: 1.5em;
    font-weight: bold;
    text-align: left;
    padding-left: 50px;
    padding-right: 50px;
}

.descriptionparents{
    color:#6C6C6C;
    font-size: 1.2em;
    text-align: center;
    padding-bottom: 50px;
}

.lignehorizontaleparent{
    width: 140px;
    height: 4px;
    background: #FF7E5A;
    text-align: left;
    margin-left: 50px;
    margin-top: 50px;
}

.avantagesparents{
    align-items: left;
    justify-content: left;
    text-align: left;
}

.soustitleavantagesparents{
    color:#373535;
    text-align: left;
}

.soustitleavantagesnounou{
    color:#373535;
    text-align: left;
}

.imageparentavantage{
    filter: drop-shadow(0px 40px 40px rgba(16, 52, 87, 0.15));
}

.imageentrepriseavantage{
    filter: drop-shadow(0px 40px 40px rgba(16, 52, 87, 0.15));
}

.titlegardenannies{
    filter: drop-shadow(0px 40px 40px rgba(16, 52, 87, 0.15));
}

.listedata {
    display: flex;
    flex-wrap: wrap;
    height: auto;
    align-content: space-between;
    align-items: center;
    justify-content: center;

  }

  .listepartenaire {
    display: flex;
    flex-wrap: wrap;
    height: auto;
    justify-content: center;

  }

.cardlist{
    filter: drop-shadow(0px 40px 40px rgba(16, 52, 87, 0.15));
}

.cardlistnounou{
    filter: drop-shadow(0px 40px 40px rgba(16, 52, 87, 0.15));
}

.cardlistcontact{
    filter: drop-shadow(0px 40px 40px rgba(16, 52, 87, 0.15));
}
.inputcontact{
    background: #F5F5F5;
    border-radius: 7.46431px;
}


.traitorangegauche{
    margin-top: 30px;
    width: 100px;
    height: 4px;
    background: #FF7E5A;
    left: 0;
}

.traitorangedroitecontact{
    width: 72px;
    height: 3px;
    background: #FF7E5A;
    margin-top: 20px;
}

.traitorangedroite{

    margin-top: 130px;
    width: 100px;
    height: 4px;
    background: #FF7E5A;
    margin-left: 80%;
}

.traitvioletgauche0{
    margin-top: 30px;
    width: 100px;
    height: 4px;
    background: #7B4EFB;
    left: 0;
}

.traitbleugauche{
    margin-top: 30px;
    width: 100px;
    height: 4px;
    background: #20A4DD;
    margin-right: 25%;
    margin-left: 75%;
}

.traitvioletgauche{
    margin-top: 22.5px;
    width: 100px;
    height: 4px;
    background: #7B4EFB;
    margin-right: 25%;
    margin-left: 25%;
}

.traitvioletdroite{
    margin-top: 22.5px;
    width: 100px;
    height: 4px;
    background: #7B4EFB;
    margin-right: 25%;
    margin-left: 25%;
}

.traitbleuapres{
    width: 100px;
    height: 4px;
    background: #20A4DD;
    left: 0%;
}

.cardlist:hover{
    filter: drop-shadow(0px 40px 40px rgba(16, 52, 87, 0.15));
    background: linear-gradient(151.02deg, #7B4EFB -23.47%, #CE3DCF 39.65%, #FF7E5A 102.77%);
    border-radius: 25px;
}

.cardcolortext:hover *{
    color: white !important;
}

.cardborder{
    box-shadow: 0 1px 0 #ccc, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 0px 0 #b9b9b9, 0 5px 0 rgba(128, 153, 233,0.2), 0 6px 1px rgba(128, 153, 233,0.2), 0 0 5px rgba(128, 153, 233,0.2), 0 1px 3px rgba(128, 153, 233,0.2), 0 3px 5px rgba(128, 153, 233,0.2), 0 5px 10px rgba(128, 153, 233,0.2), 0 10px 10px rgba(128, 153, 233,0.2), 0 20px 20px rgba(128, 153, 233,0.2), 0 0 4px white, 0 0px 3px white, 1px 1px 5px rgba(233, 157, 203,0.5), 1px 1px 10px rgba(233, 157, 203,0.5); 
  }

.tarifsparents{
    color:#918f8f;
    font-size: 1.5em;
    font-weight: bold;
    text-align: left;
    padding-top: 50px;
}

.cartetarifparents{
    min-height: 900px;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
}

.boutonpayer {
    position:absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 80px;
    background: #F0F0F0;
    color: black;
    text-align: center;
    border-radius: 0px 0px 25px 25px;
    text-align: center;
  }

  .boutonpayer:hover{
    background: #003459;
    color: white;
    font-size:30px;
  }

.textepayer{
    margin-top:20px;
    font-size:24px;
    font-weight:600;
}

.tarifnannies{
    color:#918f8f;
    font-size: 1.5em;
    font-weight: bold;
    text-align: left;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 50px;
}


.descriptiontarifs{
    color:#918f8f;
    font-size: 1.2em;
    align-items: center;
    justify-content: center;
}

.centerdata{
    align-items: center;
    justify-content: center;
}

.cartetarifentreprise{
    min-height: 750px;
    align-items: center;
    justify-content: center;
}

Thead {
    background-color: #EFEFEF;
  }

 .tableborder{
    border-bottom: solid;
    border-color: #EFEFEF;
    border-width: 1px;
 }

  Td{
    border-style: solid;
    border-color: #6573eb;
    border-width: 5px;
  }

/*

  table {
    border-collapse: collapse;
    overflow-x: hidden;
    color:#6e6d6d;
    width:auto;
  }
  thead {
    background-color: #EFEFEF;
  }
  thead, tbody {
    display: block;
  }
  tbody {
    overflow-y: scroll;
    overflow-x: hidden;
    height: auto;
  }
  td, th {
    min-width: 12vh;
    height: 60px;
    border: dashed 1px lightblue;
    overflow:hidden;
    text-overflow: ellipsis;
    max-width: 15vh;
  }
  
  Table {
    border-collapse: collapse;
    overflow-x: scroll;
  }

  */

  .main{
    max-height: 250px;
    width: 100vh;
  }
  
  .main > *{
    width: 50%;
    position: relative;
    margin-left:25vh;
  }

  .editorstyle {
    background-color:lightgray;
    padding: 1rem;
    border: 1px solid #ccc;
  }

  
  .public-DraftEditor-content{
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 1em;
    height: 100px;
    overflow-x: hidden;
    overflow-y: auto;
  }
  
  .noselect {
    -webkit-touch-callout: none;
      -webkit-user-select: none;
       -khtml-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;
  }

  .success-checkmark {
    width: 80px;
    height: 115px;
    margin: 0 auto;
  }
    
  .check-icon {
      width: 80px;
      height: 80px;
      position: relative;
      border-radius: 50%;
      box-sizing: content-box;
      border: 4px solid #7400ff;
  }
      
  .check-icon::before {
          top: 3px;
          left: -2px;
          width: 30px;
          transform-origin: 100% 50%;
          border-radius: 100px 0 0 100px;
      }
        
  .check-icon::after {
      top: 0;
      left: 30px;
      width: 60px;
      transform-origin: 0 50%;
      border-radius: 0 100px 100px 0;
      animation: rotate-circle 4.25s ease-in;
  }
  
  .check-icon::before, .check-icon::after {
      content: '';
      height: 100px;
      position: absolute;
      background: #FFFFFF;
      transform: rotate(-45deg);
  }
  
  .icon-line {
      height: 5px;
      background-color: #7400ff;
      display: block;
      border-radius: 2px;
      position: absolute;
      z-index: 10;
  }
      
  .icon-line.line-tip {
          top: 46px;
          left: 14px;
          width: 25px;
          transform: rotate(45deg);
          animation: icon-line-tip 1.25s;
      }
      
      .icon-line.line-long {
          top: 38px;
          right: 8px;
          width: 47px;
          transform: rotate(-45deg);
          animation: icon-line-long 1.25s;
      }

  
  .icon-circle {
      top: -4px;
      left: -4px;
      z-index: 10;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      position: absolute;
      box-sizing: content-box;
      border: 4px solid #FF7E5A;
  }
  
  .icon-fix {
      top: 8px;
      width: 5px;
      left: 26px;
      z-index: 1;
      height: 85px;
      position: absolute;
      transform: rotate(-45deg);
      background-color: #FFFFFF;
  }

@keyframes rotate-circle {
    0% {
        transform: rotate(-45deg);
    }
    5% {
        transform: rotate(-45deg);
    }
    12% {
        transform: rotate(-405deg);
    }
    100% {
        transform: rotate(-405deg);
    }
}

@keyframes icon-line-tip {
    0% {
        width: 0;
        left: 1px;
        top: 19px;
    }
    54% {
        width: 0;
        left: 1px;
        top: 19px;
    }
    70% {
        width: 50px;
        left: -8px;
        top: 37px;
    }
    84% {
        width: 17px;
        left: 21px;
        top: 48px;
    }
    100% {
        width: 25px;
        left: 14px;
        top: 45px;
    }
}

@keyframes icon-line-long {
    0% {
        width: 0;
        right: 46px;
        top: 54px;
    }
    65% {
        width: 0;
        right: 46px;
        top: 54px;
    }
    84% {
        width: 55px;
        right: 0px;
        top: 35px;
    }
    100% {
        width: 47px;
        right: 8px;
        top: 38px;
    }
}

.texte-clignote  {
    animation-duration: 2s;
    animation-name: clignoter;
    animation-iteration-count: infinite;
    transition: none;
 }
 @keyframes clignoter {
   0%   { opacity:1; }
   40%   {opacity:0; }
   100% { opacity:1; }
 }


